<template>
  <div class="wrapper">
    <section class="encabezado" data="buscador">

      <h2 class="title -white">Actuaciones e indicadores</h2>

      <h3 class="subtitle">
        Busca actuaciones o indicadores concretos
      </h3>

    </section>

    <nav class="breadcrumb">
      <div class="container row-start">
        <router-link to="/">Inicio</router-link>
        <router-link :to="$route.fullPath">Buscador de actuaciones e indicadores</router-link>
      </div>
    </nav>

    <a @click="back" class="back">Volver</a>

    <buscador v-model="filter"></buscador>

    <section class="contenedor-tabla" v-if="!loading">
      <div class="container" id="tabla">
        <h2 class="title" v-if="searched">Resultados de tu búsqueda</h2>
        <resultados :data="actuacionesfiltered" :type="filter.type"></resultados>
      </div>
    </section>

    <div class="loading" v-if="loading"></div>


  </div>
</template>



<script>

import resultados from '@/components/parts/resultados-busqueda'

import { mapGetters } from 'vuex'

import router from '@/router';

export default {
  name: 'buscador-view',
  data: () => ({
    filter: {
      lists: {
        ejes: [],
        areas_accion: [],
        areas_gobierno: [],
        distritos: [],
        status: [],
        tipodeindicadores: []
      },
      texto: '',
      type: "actuaciones",
      //tipoindicador: ''
    },
    searched: false,
    loading: false
  }),
  methods: {
    scrolldown() {
      scroll({
        top: document.getElementById('tabla').offsetTop,
        behavior: "smooth"
      });
    },
    back() {
      router.go(-1);
    }
  },
  computed: {
    ...mapGetters({
      actuaciones: 'getActuaciones',
      indicadores: 'getIndicadores'
    }),
    actuacionesfiltered() {

      let actuaciones = [];
      let esActuacion;

      if (this.filter.type == 'actuaciones') {
        actuaciones = JSON.parse(JSON.stringify(this.actuaciones));
        esActuacion = true;
      } else {
        actuaciones = JSON.parse(JSON.stringify(this.indicadores));
        esActuacion = false;
      }

      //console.log("this.actuaciones: ", this.actuaciones);

      //EJES
      if (this.filter.lists.ejes.length != 0) {
        let ejes = this.filter.lists.ejes.map(e => e.id);

        if (esActuacion) {//Actuaciones
          actuaciones = actuaciones.filter(a => {
            return (ejes.some(eje => a.ideje != null && a.ideje.includes(eje)));
          }
          );
        } else {//Indicadores
          actuaciones = actuaciones.filter(a => {
            return (ejes.some(eje => a.ideje != null && a.ideje2.includes(eje)));
          }
          );
        }
      }

      //ÁREAS ACCION
      if (this.filter.lists.areas_accion.length != 0) {
        let areas_accion = this.filter.lists.areas_accion.map(e => e.id);
        actuaciones = actuaciones.filter(actuacion => {

          /** Si esActuacion utilizo idareadeaccion sino idareadeaccion2 */
          if (esActuacion) {
            /** Hago un split de idareaaccion y le saco los espacios*/
            let areas_accion_actuacion = actuacion.idareaaccion.split(",").map(e => e.trim());
            return (areas_accion.some(area => areas_accion_actuacion.includes(area.toString())));
          } else {
            /** Hago un split de idareaaccion y le saco los espacios*/
            let areas_accion_actuacion = actuacion.idareadeaccion2 ? actuacion.idareadeaccion2.split(",").map(e => e.trim()) : [];
            return (areas_accion.some(area => areas_accion_actuacion.includes(area.toString())));
          }

          //return (areas_accion.some(area => actuacion.idareaaccion.includes(area)));

          //return (areas_accion.some(area => (area == actuacion.idareaaccion || area == actuacion.idareadeaccion)));
        })
      }

      //ÁREAS GOBIERNO
      if (this.filter.lists.areas_gobierno.length != 0) {
        let areas_gobierno = this.filter.lists.areas_gobierno.map(e => e.id);
        actuaciones = actuaciones.filter(actuacion => {
          return (areas_gobierno.some(area => area == actuacion.idareagobierno));
        })
      }

      //ESTADO
      if (this.filter.lists.status.length != 0) {
        let status = this.filter.lists.status.map(e => e.id);



        actuaciones = actuaciones.filter(actuacion => {
          return (status.some(estado => (estado == actuacion.idactuacioncumplimiento) || (estado == 3 && actuacion.idactuacioncumplimiento >= 3)));
        })
      }

      //DISTRITOS
      if (this.filter.lists.distritos.length != 0) {
        let distritos = this.filter.lists.distritos.map(e => e.id);

        actuaciones = actuaciones.filter(actuacion => {

          /** Hago un split de iddistrito y le saco los espacios*/
          let distritos_actuacion = actuacion.iddistrito.split(",").map(e => e.trim());

          return distritos.some(distrito => distritos_actuacion.includes(distrito.toString()));

          //return (distritos.some(distrito => distrito == actuacion.iddistrito));
        })
      }

      //INDICADORES -> COMPROMISO
      // if (this.filter.tipoindicadores) {
      //   let indicadores = this.filter.tipoindicador.map(e => e.id);
      //   actuaciones = actuaciones.filter(actuacion => {
      //     return (indicadores.some(indicador => actuacion[indicador] == 1));
      //   })
      // }


      //TIPO INDICADOR el filtro se realiza con tipoindicadores.id
      if (this.filter.lists.tipodeindicadores.length != 0) {
        let tipodeindicadores = this.filter.lists.tipodeindicadores.map(e => e.id);

        let tipodeindicadores_estrategico = tipodeindicadores.includes("estrategico") ? 1 : null;
        let tipodeindicadores_accion = tipodeindicadores.includes("accion") ? 1 : null;
        let tipodeindicadores_sectorial = tipodeindicadores.includes("sectorial") ? 1 : null;

        actuaciones = actuaciones.filter(actuacion => {
          return (tipodeindicadores_estrategico == actuacion.estrategico || tipodeindicadores_accion == actuacion.accion || tipodeindicadores_sectorial == actuacion.sectorial);
        })

      }

      //NOMBRES
      if (this.filter.texto) {
        let searchIn = ['nombre', 'ciudad', 'unidadresponsable', 'compromisodegobierno'];
        actuaciones = actuaciones.filter(actuacion => {
          let has = false;
          searchIn.forEach(key => {
            if (actuacion[key]) {
              if (actuacion[key].toLowerCase().includes(this.filter.texto.toLowerCase())) {
                has = true;
              }
            }
          })
          return has;
        })
      }

      //console.log("buscador vista: ", actuaciones);


      /** Devuelvo ordenado por nombre sin tener en cuenta los acentos */
      actuaciones = actuaciones.sort((a, b) => {
        return a.nombre.localeCompare(b.nombre, 'es', { sensitivity: 'base' });
      });

      return actuaciones;
    }
  },
  components: {
    'resultados': resultados
  }
}
</script>